<template>
	<q-item>
		<q-item-section class="full-width">
			<q-select
				class="q-pb-lg"
				v-model="selected_country"
				:options="countries"
				:label="'Select location'"
				:hint="'Select 1 option'"
				@popup-hide="getCountries"
			/>
			<q-select
				class="q-pt-sm"
				v-model="selected_timezone"
				:options="country_timezones"
				:label="'Select time zone'"
				:hint="'Select 1 option'"
			/>

		</q-item-section>
	</q-item>
</template>
<script>

import TimeZones from '@/utils/countries_timezones.js'

export default {
	name: 'TimeZonePicker',
	props: {
		existing_settings: String
	},
	data(){
		return {
			country: "",
			timezone: "",
			time_zones: [],
			countries: [],
			country_timezones: [],
			selected_timezone: "",
			selected_country: "",
		}
	},
	methods: {
		createCountriesList() {
			this.time_zones.forEach((time_zone) => {
				this.countries.push(time_zone.name)
				if (this.existing_settings){
					if (time_zone.timezones.includes(this.existing_settings)){
						this.selected_timezone = this.existing_settings
						this.selected_country = time_zone.name
						this.country_timezones = time_zone.timezones
					}
				}
				else {
					if (time_zone.name === 'United Kingdom') {
						this.selected_timezone = time_zone.timezones[0]
						this.selected_country = time_zone.name
						this.country_timezones = time_zone.timezones
					}
				}
			})
		},
		getCountries() {
			this.selected_timezone = ''
			this.time_zones.forEach((time_zone) => {
				if (time_zone.name == this.selected_country) {
					this.country_timezones = time_zone.timezones
				}
				this.selected_timezone = this.country_timezones[0]
			})
		},
	},
	created(){
		this.time_zones = TimeZones.getTimeZones()
		this.createCountriesList()
	},
	watch:{
		selected_timezone: function(selected_timezone){
			if(selected_timezone == null){
				this.selected_timezone = selected_timezone = 'Europe/London'
				this.selected_country = "United Kingdom"
				this.getCountries()
			}
			this.$emit('timezoneSelected', selected_timezone)
		},
		selected_country: function(new_val){
			if(new_val == null){
				this.selected_country = 'United Kingdom'
			}
		}
	}
}
</script>
